<template>
  <svg
    width="380"
    height="260"
    viewBox="0 0 380 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="380" height="260" fill="#FFE100" />
    <g clip-path="url(#clip0_3132_4880)">
      <path
        d="M213.296 64.446H91.6908C81.5166 64.446 73.2659 72.6967 73.2659 82.871V180.077C73.2659 185.001 79.2208 187.47 82.7031 183.987L105.152 161.538C108.332 158.358 112.644 156.575 117.139 156.575H213.296C223.47 156.575 231.721 148.324 231.721 138.15V82.871C231.721 72.6967 223.47 64.446 213.296 64.446Z"
        fill="white"
      />
      <path
        d="M93.0727 77.7673C94.3243 77.7673 95.3389 76.7526 95.3389 75.501C95.3389 74.2494 94.3243 73.2347 93.0727 73.2347C91.821 73.2347 90.8064 74.2494 90.8064 75.501C90.8064 76.7526 91.821 77.7673 93.0727 77.7673Z"
        stroke="#F1F1F1"
        stroke-miterlimit="10"
      />
      <path
        d="M99.8678 77.7673C101.119 77.7673 102.134 76.7526 102.134 75.501C102.134 74.2494 101.119 73.2347 99.8678 73.2347C98.6162 73.2347 97.6016 74.2494 97.6016 75.501C97.6016 76.7526 98.6162 77.7673 99.8678 77.7673Z"
        stroke="#F1F1F1"
        stroke-miterlimit="10"
      />
      <path
        d="M106.667 77.7673C107.918 77.7673 108.933 76.7526 108.933 75.501C108.933 74.2494 107.918 73.2347 106.667 73.2347C105.415 73.2347 104.4 74.2494 104.4 75.501C104.4 76.7526 105.415 77.7673 106.667 77.7673Z"
        stroke="#F1F1F1"
        stroke-miterlimit="10"
      />
      <g style="mix-blend-mode:multiply">
        <path
          d="M73.2659 86.556H231.721"
          stroke="#F1F1F1"
          stroke-miterlimit="10"
        />
      </g>
      <path
        d="M286.995 115.115C298.443 115.115 307.724 105.834 307.724 94.3866C307.724 82.9388 298.443 73.6585 286.995 73.6585C275.548 73.6585 266.267 82.9388 266.267 94.3866C266.267 105.834 275.548 115.115 286.995 115.115Z"
        fill="white"
        stroke="#4570F5"
        stroke-miterlimit="10"
      />
      <path
        d="M286.996 92.1682C289.074 92.1682 290.762 90.4805 290.762 88.4022C290.762 86.3238 289.074 84.6361 286.996 84.6361C284.917 84.6361 283.229 86.3238 283.229 88.4022C283.229 90.4805 284.917 92.1682 286.996 92.1682Z"
        fill="#4570F5"
      />
      <path
        d="M286.996 94.055C282.375 94.055 278.575 97.482 278.155 101.856C278.111 102.298 278.48 102.663 278.925 102.663H295.069C295.515 102.663 295.88 102.302 295.84 101.856C295.419 97.4783 291.62 94.055 286.999 94.055H286.996Z"
        fill="#4570F5"
      />
      <path
        d="M97.2183 118.859H73.2659V156.571H97.2183V118.859Z"
        fill="#4570F5"
        stroke="black"
        stroke-miterlimit="10"
      />
      <mask
        id="mask0_3132_4880"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="73"
        y="64"
        width="159"
        height="122"
      >
        <path
          d="M213.296 64.4459H91.6908C81.5166 64.4459 73.2659 72.6966 73.2659 82.8708V180.077C73.2659 185 79.2208 187.469 82.7031 183.987L105.152 161.538C108.332 158.358 112.644 156.574 117.139 156.574H213.296C223.47 156.574 231.721 148.324 231.721 138.149V82.8708C231.721 72.6966 223.47 64.4459 213.296 64.4459Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3132_4880)">
        <path
          d="M207.448 143.522L228.592 122.396C229.108 121.77 230.423 119.555 229.705 117.72C228.666 115.067 226.996 115.693 226.996 115.693C228.036 114.658 227.122 112.627 225.655 112.959C225.917 112.251 225.755 111.699 225.239 111.257C224.649 110.752 223.794 110.859 223.433 111.146C223.665 110.527 223.547 109.735 222.947 109.285C222.269 108.776 221.421 108.817 220.846 109.222L217.404 110.877L212.01 118.324C212.256 117.09 212.382 115.83 212.382 114.558C212.382 114.558 213.24 113.434 214.67 111.397C215.134 110.737 214.998 109.834 214.364 109.329C213.764 108.854 212.898 108.92 212.378 109.48L202.565 120.067C201.526 121.188 200.951 122.658 200.951 124.183V127.537L196.205 132.283L207.448 143.526V143.522Z"
          fill="#F8D3B3"
        />
        <path
          d="M212.378 114.551C212.378 117.926 211.49 121.243 209.802 124.169C209.592 124.53 209.655 124.987 209.95 125.282L213.016 128.348C214.287 129.619 216.321 129.7 217.688 128.539L221.815 125.035L227.214 119.153L213.06 116.872L212.382 114.555"
          fill="#F1A280"
        />
        <path
          d="M212.378 114.551C212.378 115.819 212.253 117.075 212.01 118.306C211.601 120.358 210.86 122.341 209.802 124.169"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M214.666 111.389C213.237 113.427 212.378 114.551 212.378 114.551"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M229.436 84.738L211.054 120.753C210.612 121.618 210.956 122.677 211.82 123.118C212.685 123.559 213.744 123.216 214.185 122.352L232.567 86.3362C233.009 85.4715 232.666 84.4128 231.801 83.9715C230.936 83.5302 229.878 83.8734 229.436 84.738Z"
          fill="#4570F5"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M207.448 143.522L228.588 122.393"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M102.097 126.347L102.388 156.571H161.801L169.923 136.996L169.477 149.112C169.006 161.885 184.438 168.613 193.478 159.574L213.237 139.815L200.951 127.529L190.334 138.146L191.094 117.304C191.55 104.786 181.524 94.3866 168.998 94.3866H133.751C116.148 94.3866 101.928 108.747 102.097 126.347Z"
          fill="#4570F5"
          stroke="black"
          stroke-miterlimit="10"
        />
        <path
          d="M190.331 138.146L186.487 141.993"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M223.429 111.139C223.429 111.139 219.21 114.507 216.811 118.394C216.258 119.286 215.105 119.441 214.453 118.954C213.701 118.394 213.951 117.34 214.63 116.342C217.342 112.362 220.883 109.233 220.883 109.233"
          fill="#F8D3B3"
        />
        <path
          d="M223.429 111.139C223.429 111.139 219.21 114.507 216.811 118.394C216.258 119.286 215.105 119.441 214.453 118.954C213.701 118.394 213.951 117.34 214.63 116.342C217.342 112.362 220.883 109.233 220.883 109.233"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M225.651 112.955C225.651 112.955 220.596 117.451 218.167 121.18C217.633 121.998 216.553 122.109 215.964 121.633C215.311 121.11 215.466 120.06 216.085 119.176C219.214 114.706 223.433 111.139 223.433 111.139"
          fill="#F8D3B3"
        />
        <path
          d="M225.651 112.955C225.651 112.955 220.596 117.451 218.167 121.18C217.633 121.998 216.553 122.109 215.964 121.633C215.311 121.11 215.466 120.06 216.085 119.176C219.214 114.706 223.433 111.139 223.433 111.139"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M227.21 119.146C227.21 119.146 224.391 121.917 221.708 125.425C221.1 126.218 220.043 126.31 219.519 125.735C219.037 125.204 219.195 124.301 219.733 123.553C222.851 119.227 226.993 115.69 226.993 115.69"
          fill="#F8D3B3"
        />
        <path
          d="M227.21 119.146C227.21 119.146 224.391 121.917 221.708 125.425C221.1 126.218 220.043 126.31 219.519 125.735C219.037 125.204 219.195 124.301 219.733 123.553C222.851 119.227 226.993 115.69 226.993 115.69"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M226.993 115.69C226.993 115.69 222.851 119.227 219.87 123.369C219.287 124.18 218.215 124.279 217.673 123.723C217.157 123.192 217.356 122.286 217.88 121.49C220.596 117.318 225.651 112.959 225.651 112.959"
          fill="#F8D3B3"
        />
        <path
          d="M226.993 115.69C226.993 115.69 222.851 119.227 219.87 123.369C219.287 124.18 218.215 124.279 217.673 123.723C217.157 123.192 217.356 122.286 217.88 121.49C220.596 117.318 225.651 112.959 225.651 112.959"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M125.353 115.115L123.194 118.859"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M167.233 94.3866L159.366 102.254C157.35 104.27 154.085 104.27 152.069 102.254L144.202 94.3866"
          fill="white"
        />
        <path
          d="M149.958 100.143L155.485 94.6151C155.614 94.4861 155.821 94.4861 155.95 94.6151L161.477 100.143"
          fill="white"
        />
        <path
          d="M149.958 100.143L155.485 94.6151C155.614 94.4861 155.821 94.4861 155.95 94.6151L161.477 100.143"
          stroke="#4570F5"
          stroke-miterlimit="10"
        />
        <path
          d="M178.712 115.115L161.802 156.571H165.627"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M155.718 101.429C156.426 101.429 157 100.855 157 100.146C157 99.438 156.426 98.8639 155.718 98.8639C155.009 98.8639 154.435 99.438 154.435 100.146C154.435 100.855 155.009 101.429 155.718 101.429Z"
          fill="black"
        />
        <path
          d="M199.208 156.571H196.481L213.237 139.815L200.756 127.334"
          stroke="black"
          stroke-miterlimit="10"
        />
        <path
          d="M283.207 136.407H209.508C201.367 136.407 194.768 143.006 194.768 151.147V195.367C194.768 203.507 201.367 210.107 209.508 210.107H283.207C291.348 210.107 297.947 203.507 297.947 195.367V151.147C297.947 143.006 291.348 136.407 283.207 136.407Z"
          fill="black"
          stroke="black"
          stroke-miterlimit="10"
        />
        <path
          d="M225.891 91.7076L229.443 84.7467C229.885 83.8807 230.943 83.538 231.809 83.9802C232.675 84.4224 233.018 85.48 232.575 86.346L230.261 90.8822C230.261 90.8822 228.382 91.575 225.891 91.7076Z"
          fill="black"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M207.448 143.522L228.588 122.393"
          stroke="black"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M144.202 94.3866H167.233"
          stroke="#4570F5"
          stroke-miterlimit="10"
        />
        <path
          d="M79.7146 177.759L68.468 189.002L81.6198 195.436L83.9303 182.719L79.7146 177.759Z"
          fill="black"
          stroke="black"
          stroke-miterlimit="10"
        />
      </g>
      <path
        d="M213.296 64.446H91.6908C81.5166 64.446 73.2659 72.6967 73.2659 82.871V180.077C73.2659 185.001 79.2208 187.47 82.7031 183.987L105.152 161.538C108.332 158.358 112.644 156.575 117.139 156.575H213.296C223.47 156.575 231.721 148.324 231.721 138.15V82.871C231.721 72.6967 223.47 64.446 213.296 64.446Z"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M109.195 170.39H87.0846C83.0143 170.39 79.7146 173.689 79.7146 177.76V199.87C79.7146 203.94 83.0143 207.24 87.0846 207.24H109.195C113.265 207.24 116.565 203.94 116.565 199.87V177.76C116.565 173.689 113.265 170.39 109.195 170.39Z"
        fill="#FFD700"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M107.669 183.037C107.827 183.195 107.92 183.412 107.92 183.637V193.951C107.92 194.29 107.739 194.615 107.433 194.755C107.094 194.913 106.715 194.832 106.468 194.585L101.456 190.425V195.061C101.456 195.367 101.209 195.613 100.903 195.613H90.8396C89.5241 195.613 88.4407 194.593 88.3633 193.303V184.466C88.3596 183.154 89.3988 182.082 90.6996 182.008H100.9C101.205 182.005 101.452 182.252 101.452 182.557V187.193L106.464 183.033C106.796 182.701 107.334 182.701 107.665 183.033L107.669 183.037Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M123.194 118.859H97.2183V131.428H123.194V118.859Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M125.497 131.428H95.8364V143.998H125.497V131.428Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M123.194 144.001H92.2731V156.571H123.194V144.001Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M174.135 79.4071C171.117 85.5131 165.428 89.7804 158.813 89.7804C137.919 89.7804 141.582 55.2336 161.835 55.2336C165.579 55.2336 169.743 56.2432 172.543 58.8301C175.606 61.6602 176.752 65.6363 177.242 69.6603C177.566 72.3098 177.828 74.834 178.203 77.5536C178.34 78.5301 177.584 79.4034 176.6 79.4034H174.135V79.4071Z"
        fill="#F8D3B3"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M168.67 69.4245L167.908 73.7543"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M148.823 73.7543C146.192 73.7543 144.198 71.3775 144.655 68.787L144.803 67.9431C145.16 65.92 146.918 64.446 148.97 64.446H150.463"
        fill="#F8D3B3"
      />
      <path
        d="M148.823 73.7543C146.192 73.7543 144.198 71.3775 144.655 68.787L144.803 67.9431C145.16 65.92 146.918 64.446 148.97 64.446H150.463"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M147.272 64.446C150.013 59.2023 154.97 55.2336 161.835 55.2336C165.579 55.2336 169.743 56.2432 172.543 58.8301C172.595 58.878 172.65 58.9296 172.702 58.9775C169.201 62.4783 164.451 64.446 159.498 64.446H147.272Z"
        fill="black"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M202.241 150.811V156.571H196.481L202.241 150.811Z"
        fill="black"
      />
      <path
        d="M290.681 128.933H216.981C208.84 128.933 202.241 135.533 202.241 143.673V187.893C202.241 196.034 208.84 202.633 216.981 202.633H290.681C298.821 202.633 305.421 196.034 305.421 187.893V143.673C305.421 135.533 298.821 128.933 290.681 128.933Z"
        fill="#4570F5"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M279.073 162.795V176.801"
        stroke="white"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M274.286 156.571L259.553 152.624C255.806 151.618 251.855 151.618 248.108 152.624L233.375 156.571H225.095V161.958C225.095 162.88 225.64 163.801 226.735 164.096L248.111 169.822C251.859 170.828 255.809 170.828 259.557 169.822L280.934 164.096C282.024 163.805 282.574 162.883 282.574 161.958V156.571H274.293H274.286Z"
        fill="white"
      />
      <path
        d="M226.731 158.708L248.108 164.435C251.855 165.441 255.802 165.441 259.553 164.435L280.93 158.708C283.115 158.122 283.115 155.023 280.93 154.437L259.553 148.711C255.806 147.705 251.859 147.705 248.108 148.711L226.731 154.437C224.546 155.023 224.546 158.122 226.731 158.708Z"
        fill="white"
      />
      <path
        d="M226.731 158.708L248.108 164.435C251.855 165.441 255.802 165.441 259.553 164.435L280.93 158.708"
        stroke="#4570F5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M281.332 179.06C281.332 177.813 280.32 176.801 279.073 176.801C277.825 176.801 276.814 177.813 276.814 179.06C276.814 180.308 277.825 181.319 279.073 181.319C280.32 181.319 281.332 180.308 281.332 179.06Z"
        fill="white"
      />
      <path
        d="M235.406 175.147V166.41L247.154 169.557C249.331 170.139 251.576 170.437 253.831 170.437C256.086 170.437 258.33 170.143 260.508 169.557L272.256 166.41V175.147C272.256 178.15 270.24 180.778 267.34 181.555L265.276 182.108C261.547 183.106 257.696 183.615 253.831 183.615C249.965 183.615 246.114 183.106 242.385 182.108L240.322 181.555C237.421 180.778 235.406 178.15 235.406 175.147Z"
        fill="white"
      />
      <path
        d="M236.655 166.756L248.104 169.826C251.852 170.832 255.798 170.832 259.55 169.826L270.999 166.756"
        stroke="#4570F5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M227.807 87.9589C236.033 84.5515 239.94 75.1207 236.532 66.8947C233.125 58.6686 223.694 54.7622 215.468 58.1696C207.242 61.5769 203.336 71.0077 206.743 79.2338C210.15 87.4599 219.581 91.3662 227.807 87.9589Z"
        fill="#FFD700"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M215.396 68.3816C216.258 68.3816 216.955 69.0817 216.955 69.9403V76.1827C216.955 77.045 216.255 77.7415 215.396 77.7415C214.537 77.7415 213.837 77.0413 213.837 76.1827V69.9403C213.837 69.0781 214.537 68.3816 215.396 68.3816Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M221.638 64.2213C222.501 64.2213 223.197 64.9214 223.197 65.78V80.3431C223.197 81.2054 222.497 81.9019 221.638 81.9019C220.78 81.9019 220.08 81.2017 220.08 80.3431V65.7837C220.08 64.9214 220.78 64.2249 221.638 64.2249V64.2213Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M227.877 68.3816C228.739 68.3816 229.436 69.0817 229.436 69.9403V76.1827C229.436 77.045 228.736 77.7415 227.877 77.7415C227.019 77.7415 226.318 77.0413 226.318 76.1827V69.9403C226.318 69.0781 227.019 68.3816 227.877 68.3816Z"
        fill="black"
      />
      <g style="mix-blend-mode:multiply">
        <path
          d="M97.2183 121.998H123.194"
          stroke="#F1F1F1"
          stroke-miterlimit="10"
        />
      </g>
      <g style="mix-blend-mode:multiply">
        <path
          d="M97.2183 125.142H123.194"
          stroke="#F1F1F1"
          stroke-miterlimit="10"
        />
      </g>
      <g style="mix-blend-mode:multiply">
        <path
          d="M97.2183 128.285H123.194"
          stroke="#F1F1F1"
          stroke-miterlimit="10"
        />
      </g>
      <g style="mix-blend-mode:multiply">
        <path
          d="M95.8364 134.571H125.497"
          stroke="#F1F1F1"
          stroke-miterlimit="10"
        />
      </g>
      <g style="mix-blend-mode:multiply">
        <path
          d="M95.8364 137.715H125.497"
          stroke="#F1F1F1"
          stroke-miterlimit="10"
        />
      </g>
      <g style="mix-blend-mode:multiply">
        <path
          d="M95.8364 140.858H125.497"
          stroke="#F1F1F1"
          stroke-miterlimit="10"
        />
      </g>
      <g style="mix-blend-mode:multiply">
        <path
          d="M92.2731 147.672H123.194"
          stroke="#F1F1F1"
          stroke-miterlimit="10"
        />
      </g>
      <g style="mix-blend-mode:multiply">
        <path
          d="M92.2731 150.811H123.194"
          stroke="#F1F1F1"
          stroke-miterlimit="10"
        />
      </g>
      <g style="mix-blend-mode:multiply">
        <path
          d="M92.2731 153.955H123.194"
          stroke="#F1F1F1"
          stroke-miterlimit="10"
        />
      </g>
      <path
        d="M114.195 134.571V139.745C114.195 139.856 114.313 139.922 114.409 139.867L116.413 138.71C116.469 138.677 116.535 138.677 116.59 138.71L118.595 139.867C118.691 139.922 118.809 139.852 118.809 139.745V134.571H114.195Z"
        fill="#3D6DFF"
      />
      <path
        d="M139.596 193.421C146.637 193.421 152.346 187.712 152.346 180.671C152.346 173.629 146.637 167.921 139.596 167.921C132.554 167.921 126.846 173.629 126.846 180.671C126.846 187.712 132.554 193.421 139.596 193.421Z"
        fill="#FFE100"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M135.144 185.122L144.044 176.223"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M144.044 182.557V176.223H137.709"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M101.456 187.197V190.429"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M92.273 144.001H81.4281"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3132_4880">
        <rect
          width="236.8"
          height="156.557"
          fill="white"
          transform="translate(72 52)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
