<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9819 3.55873L18.2898 3.79587L18.6771 3.76285L22.4272 3.44302L23.6942 6.98714L23.825 7.3531L24.1686 7.53468L27.4963 9.29311L26.6461 12.9596L26.5583 13.3382L26.7492 13.6767L28.598 16.9551L25.9004 19.5798L25.6219 19.8509L25.5995 20.2388L25.3823 23.9963L21.694 24.746L21.3131 24.8234L21.0845 25.1377L18.8704 28.1813L15.3622 26.8179L15 26.6771L14.6378 26.8179L11.1296 28.1813L8.91549 25.1377L8.68686 24.8234L8.30601 24.746L4.61766 23.9963L4.40053 20.2389L4.37811 19.8509L4.09956 19.5798L1.40203 16.9551L3.25082 13.6767L3.44172 13.3382L3.35392 12.9596L2.50365 9.29311L5.83139 7.53468L6.17501 7.3531L6.30583 6.98714L7.57278 3.44302L11.3229 3.76285L11.7102 3.79587L12.0181 3.55873L15 1.2622L17.9819 3.55873Z"
      fill="#FFE100"
      stroke="black"
      stroke-width="2"
    />
    <path
      d="M13.7498 15L9.1665 13.75L17.9165 6.25L15.8332 12.0833L21.2498 13.75L9.1665 22.9167L13.7498 15Z"
      fill="white"
      stroke="black"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </svg>
</template>
