<template>
  <svg
    width="82"
    height="82"
    viewBox="0 0 82 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41 80.9999C63.0914 80.9999 81 63.0913 81 40.9999C81 18.9085 63.0914 0.999878 41 0.999878C18.9086 0.999878 1 18.9085 1 40.9999C1 63.0913 18.9086 80.9999 41 80.9999Z"
      fill="white"
      stroke="black"
      stroke-miterlimit="10"
    />
    <mask id="path-2-inside-1_3132_2499" fill="white">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.1029 25.9999C25.2847 25.9999 23 28.442 23 31.4545V49.3452C23 52.3577 25.2847 54.7998 28.1029 54.7998H30.0755L28.2787 62L40.6218 54.7998H53.8971C56.7153 54.7998 59 52.3577 59 49.3452V31.4544C59 28.442 56.7153 25.9999 53.8971 25.9999H28.1029Z"
      />
    </mask>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.1029 25.9999C25.2847 25.9999 23 28.442 23 31.4545V49.3452C23 52.3577 25.2847 54.7998 28.1029 54.7998H30.0755L28.2787 62L40.6218 54.7998H53.8971C56.7153 54.7998 59 52.3577 59 49.3452V31.4544C59 28.442 56.7153 25.9999 53.8971 25.9999H28.1029Z"
      fill="#FFE100"
    />
    <path
      d="M30.0755 54.7998L31.0457 55.0419L31.3557 53.7998H30.0755V54.7998ZM28.2787 62L27.3084 61.7578L26.7343 64.0586L28.7825 62.8637L28.2787 62ZM40.6218 54.7998V53.7998H40.3515L40.118 53.936L40.6218 54.7998ZM24 31.4545C24 28.9304 25.8987 26.9999 28.1029 26.9999V24.9999C24.6706 24.9999 22 27.9535 22 31.4545H24ZM24 49.3452V31.4545H22V49.3452H24ZM28.1029 53.7998C25.8987 53.7998 24 51.8692 24 49.3452H22C22 52.8462 24.6706 55.7998 28.1029 55.7998V53.7998ZM30.0755 53.7998H28.1029V55.7998H30.0755V53.7998ZM29.2489 62.2421L31.0457 55.0419L29.1052 54.5577L27.3084 61.7578L29.2489 62.2421ZM40.118 53.936L27.7748 61.1362L28.7825 62.8637L41.1257 55.6636L40.118 53.936ZM53.8971 53.7998H40.6218V55.7998H53.8971V53.7998ZM58 49.3452C58 51.8692 56.1013 53.7998 53.8971 53.7998V55.7998C57.3293 55.7998 60 52.8462 60 49.3452H58ZM58 31.4544V49.3452H60V31.4544H58ZM53.8971 26.9999C56.1013 26.9999 58 28.9304 58 31.4544H60C60 27.9535 57.3293 24.9999 53.8971 24.9999V26.9999ZM28.1029 26.9999H53.8971V24.9999H28.1029V26.9999Z"
      fill="black"
      mask="url(#path-2-inside-1_3132_2499)"
    />
    <path d="M36.1398 35.9V38.6" stroke="black" stroke-linecap="round" />
    <path d="M45.861 35.8999V38.5999" stroke="black" stroke-linecap="round" />
    <path
      d="M34.5347 43.0995C35.9768 45.0818 38.3374 46.376 41 46.376C43.6627 46.376 46.0233 45.0818 47.4654 43.0995"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
