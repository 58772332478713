<template>
  <svg
    width="82"
    height="82"
    viewBox="0 0 82 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41 81C63.0914 81 81 63.0914 81 41C81 18.9086 63.0914 1 41 1C18.9086 1 1 18.9086 1 41C1 63.0914 18.9086 81 41 81Z"
      fill="white"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M60.4241 40.8393V49.4215C60.4241 50.1777 59.6006 50.6542 58.948 50.2761L44.0832 41.6991C43.4254 41.321 43.4254 40.3732 44.0832 39.9899L58.948 31.4128C59.6057 31.0348 60.4241 31.5061 60.4241 32.2675V40.8497V40.8393Z"
      fill="#4570F5"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.4277 31.1943C22.4277 30.2586 23.1862 29.5 24.122 29.5H48.2363C49.1721 29.5 49.9306 30.2586 49.9306 31.1943V50.1781C49.9306 51.1139 49.1721 51.8724 48.2363 51.8724H24.122C23.1862 51.8724 22.4277 51.1139 22.4277 50.1781V31.1943Z"
      fill="#FFE100"
      stroke="black"
    />
    <path
      d="M36.1497 35.0718V45.9527"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.5895 38.3362V43.2326"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.7091 38.3362V43.2326"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
