<template>
  <svg
    width="380"
    height="260"
    viewBox="0 0 380 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="380" height="260" fill="#FFE100" />
    <g clip-path="url(#clip0_3132_962)">
      <path
        d="M238.094 58.1303H103.344C93.4252 58.1303 85.384 66.1715 85.384 76.0908V147.932C85.384 157.852 93.4252 165.893 103.344 165.893H238.094C248.014 165.893 256.055 157.852 256.055 147.932V76.0908C256.055 66.1715 248.014 58.1303 238.094 58.1303Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M85.384 145.687H256.055V147.918C256.055 157.832 248.009 165.879 238.094 165.879H103.344C93.4303 165.879 85.384 157.832 85.384 147.918V145.687Z"
        fill="#F1F1F1"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M197.012 136.445H145.717V149.039H197.012V136.445Z"
        fill="black"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M227.135 138.949H279.22C287.152 138.949 293.589 145.386 293.589 153.317V165.882H212.767V153.317C212.767 145.386 219.204 138.949 227.135 138.949Z"
        fill="black"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M103.944 79.0865C107.251 79.0865 109.932 76.4056 109.932 73.0985C109.932 69.7915 107.251 67.1105 103.944 67.1105C100.637 67.1105 97.9563 69.7915 97.9563 73.0985C97.9563 76.4056 100.637 79.0865 103.944 79.0865Z"
        stroke="#F1F1F1"
        stroke-miterlimit="10"
      />
      <path
        d="M241.514 145.687C253.357 145.687 265.768 143.482 265.768 143.482C259.148 138.417 252.56 136.757 244.409 134.674C228.902 130.716 208.575 131.571 199.131 145.684H241.514V145.687Z"
        fill="#F1F1F1"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M296.419 115.55C308.818 115.55 318.87 105.498 318.87 93.0993C318.87 80.7002 308.818 70.6487 296.419 70.6487C284.02 70.6487 273.969 80.7002 273.969 93.0993C273.969 105.498 284.02 115.55 296.419 115.55Z"
        fill="#F1F1F1"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M286.541 129.958H234.456C226.52 129.958 220.087 136.391 220.087 144.326V187.431C220.087 195.366 226.52 201.799 234.456 201.799H286.541C294.476 201.799 300.909 195.366 300.909 187.431V144.326C300.909 136.391 294.476 129.958 286.541 129.958Z"
        fill="#4570F5"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M220.087 144.326C220.087 137.009 225.558 130.967 232.634 130.073C232.473 131.495 232.713 132.946 232.692 134.43C232.595 141.075 223.51 145.687 217.76 145.687H213.316"
        fill="black"
      />
      <path
        d="M220.087 144.326C220.087 137.009 225.558 130.967 232.634 130.073C232.473 131.495 232.713 132.946 232.692 134.43C232.595 141.075 223.511 145.687 217.76 145.687H213.316"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M267.259 120.992C269.738 120.992 271.749 118.982 271.749 116.502C271.749 114.022 269.738 112.012 267.259 112.012C264.779 112.012 262.769 114.022 262.769 116.502C262.769 118.982 264.779 120.992 267.259 120.992Z"
        fill="#FFD700"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M314.38 81.9423C316.859 81.9423 318.87 79.932 318.87 77.4522C318.87 74.9724 316.859 72.9621 314.38 72.9621C311.9 72.9621 309.889 74.9724 309.889 77.4522C309.889 79.932 311.9 81.9423 314.38 81.9423Z"
        fill="#4570F5"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M179.676 82.8259C179.676 78.4112 176.663 75.1999 172.941 75.1999C171.3 75.1999 169.795 75.8285 168.627 76.9062L179.504 84.5753C179.612 84.0185 179.676 83.433 179.676 82.8259Z"
        fill="black"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M168.627 76.9062C167.147 78.2712 166.206 80.3617 166.206 82.8259C166.206 87.2406 169.22 90.4519 172.941 90.4519C176.149 90.4519 178.825 88.0632 179.504 84.5753L168.627 76.9062Z"
        fill="#F8D3B3"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M162.84 107.522L149.873 132.925L141.83 135.08L146.32 131.603L156.967 103.617C158.749 97.9163 162.23 94.0368 168.2 94.0368H177.686C183.656 94.0368 187.141 97.9199 188.919 103.621L199.566 131.603L204.056 135.08L196.013 132.925L183.046 107.522"
        fill="#F8D3B3"
      />
      <path
        d="M162.84 107.522L149.873 132.925L141.83 135.08L146.32 131.603L156.967 103.617C158.749 97.9163 162.23 94.0368 168.2 94.0368H177.686C183.656 94.0368 187.141 97.9199 188.919 103.621L199.566 131.603L204.056 135.08L196.013 132.925L183.046 107.522"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M104.372 145.687C92.5288 145.687 80.1182 143.482 80.1182 143.482C86.7384 138.417 93.7106 134.189 101.861 132.109C117.368 128.151 137.311 131.571 146.759 145.687H104.372Z"
        fill="black"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M133.399 71.1013H112.925V75.0922H133.399V71.1013Z"
        fill="#F1F1F1"
      />
      <path
        d="M164.604 94.5828C165.685 94.2272 166.881 94.0404 168.196 94.0404H177.683C178.997 94.0404 180.194 94.2308 181.278 94.5828L183.042 107.522L180.797 123.984H165.082L162.837 107.522L164.6 94.5828H164.604Z"
        fill="#4570F5"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M173.376 100.786H172.51C170.129 100.786 168.2 98.8574 168.2 96.4759V94.0368H177.686V96.4759C177.686 98.8574 175.757 100.786 173.376 100.786Z"
        fill="#F8D3B3"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M180.079 146.014L151.651 151.025C146.216 151.984 140.911 148.755 139.265 143.485L139.046 142.789C137.951 139.283 139.995 135.568 143.544 134.617L165.082 128.844V123.98H180.797V128.844L202.335 134.617C205.884 135.568 207.928 139.283 206.833 142.789C205.065 148.446 199.131 151.683 193.42 150.095L180.075 146.011L180.079 146.014Z"
        fill="#FFD700"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M197.127 139.505C187.787 137.102 176.246 133.611 165.085 128.851"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M144.147 134.462C148.425 135.26 152.624 136.021 156.791 137.293C164.719 139.71 172.295 143.18 180.079 146.018"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M85.3841 145.687C73.541 145.687 61.1304 143.482 61.1304 143.482C67.7506 138.417 74.7228 134.189 82.8733 132.109C98.3803 128.151 118.324 131.571 127.771 145.687H85.3841Z"
        fill="#4570F5"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M85.384 145.687H140.224"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M205.442 145.687H220.087"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M131.639 145.637C131.639 145.637 118.755 124.631 121.754 101.663C121.754 101.663 135.063 112.364 137.283 145.637H131.639Z"
        fill="#F1F1F1"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M119.731 145.637L117.16 144.355C114.135 142.842 111.383 140.641 109.426 137.867C107.712 135.436 107.008 132.31 104.785 130.241C101.642 127.314 96.796 127.249 93.1716 125.065C89.7196 122.985 87.0112 119.792 85.7899 115.923C85.0032 113.43 84.4716 109.181 81.9895 107.78C81.0304 107.238 79.8737 107.213 78.7781 107.105C74.6257 106.695 70.9438 104.565 69.0148 100.783L65.5808 94.0584L77.1617 94.0512C84.2094 94.0476 90.9625 96.8422 94.1379 102.805C95.291 104.968 97.5432 106.541 99.8996 107.216C104.307 108.477 107.73 108.34 111.283 111.818C113.618 114.106 114.329 117.296 115.134 120.406C116.139 124.3 117.709 126.247 120.91 128.657C125.296 131.962 128.557 135.809 130.217 141.108L131.639 145.648H119.731V145.637Z"
        fill="#FFD700"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.5845 94.0512C76.8852 95.6712 87.8195 103.642 96.1603 110.859C107.669 120.819 118.535 131.685 125.202 145.562"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M232.128 124.552C230.716 125.511 229.218 126.287 228.353 127.928C227.243 130.033 227.807 132.192 227.774 134.43C227.677 141.075 218.593 145.687 212.842 145.687H208.399C208.399 145.687 212.716 126.412 235.806 114.562V118.057C235.806 120.166 234.887 122.267 233.299 123.664C232.929 123.991 232.534 124.279 232.132 124.552H232.128Z"
        fill="#F1F1F1"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M287.808 92.4275C289.048 92.4275 290.053 91.4223 290.053 90.1824C290.053 88.9425 289.048 87.9373 287.808 87.9373C286.568 87.9373 285.563 88.9425 285.563 90.1824C285.563 91.4223 286.568 92.4275 287.808 92.4275Z"
        fill="#F8D3B3"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M301.538 94.6223L306.412 105.284L305.421 106.099H308.603L303.197 90.1143"
        fill="#4570F5"
      />
      <path
        d="M301.538 94.6223L306.412 105.284L305.421 106.099H308.603L303.197 90.1143"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M291.538 77.4522V99.1627"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M302.537 88.7421C301.764 87.6896 300.349 87.219 299.088 87.1867C297.429 87.1472 295.769 87.1831 294.11 87.1831H293.783L292.662 77.4522L291.541 76.6906V103.703L292.662 102.909L293.783 92.9879V92.4276H299.034L293.176 104.978L291.538 106.099H294.72L303 92.1582C303.431 91.0231 303.255 89.7227 302.537 88.7421Z"
        fill="#F8D3B3"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M293.783 92.4276H297.817L297.856 87.1903C296.581 87.1508 295.306 87.1867 294.034 87.1867L291.538 87.9375V91.6768L293.783 92.4276Z"
        fill="#FFD700"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M297.817 92.4276H299.034L293.176 104.978L291.538 106.099H294.72L303 92.1582C303.431 91.0231 303.255 89.7227 302.537 88.7421C301.764 87.6896 300.349 87.2191 299.088 87.1867C298.679 87.1759 298.269 87.1724 297.856 87.1688L297.817 92.424V92.4276Z"
        fill="#4570F5"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M242.531 179.356H238.037C235.555 179.356 233.543 177.344 233.543 174.862V156.895C233.543 154.413 235.555 152.401 238.037 152.401H242.531"
        fill="white"
      />
      <path
        d="M278.466 152.405H282.96C285.442 152.405 287.453 154.416 287.453 156.898V174.866C287.453 177.348 285.442 179.36 282.96 179.36H278.466"
        fill="white"
      />
      <path
        d="M251.514 181.601C251.514 182.84 250.509 183.846 249.269 183.846H247.024C244.542 183.846 242.531 181.835 242.531 179.352V152.401C242.531 149.919 244.542 147.907 247.024 147.907H249.269C250.509 147.907 251.514 148.913 251.514 150.152V181.597V181.601Z"
        fill="white"
      />
      <path
        d="M278.466 179.356C278.466 181.838 276.454 183.85 273.972 183.85H271.727C270.488 183.85 269.482 182.844 269.482 181.605V150.16C269.482 148.92 270.488 147.915 271.727 147.915H273.972C276.454 147.915 278.466 149.926 278.466 152.408V179.36V179.356Z"
        fill="white"
      />
      <path
        d="M269.482 164.097H251.514V167.689H269.482V164.097Z"
        fill="white"
      />
      <path
        d="M263.056 118.082C263.056 118.082 252.75 121.696 237.495 129.958"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M242.531 178.232V153.525"
        stroke="#4570F5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M278.466 178.232V153.525"
        stroke="#4570F5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M259.493 93.3689C264.075 91.471 266.251 86.2178 264.353 81.6357C262.455 77.0536 257.202 74.8777 252.62 76.7757C248.038 78.6736 245.862 83.9268 247.76 88.5089C249.658 93.091 254.911 95.2669 259.493 93.3689Z"
        fill="#FFD700"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M256.055 81.1412V89.0007"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M259.424 83.3863V86.7557"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M252.689 83.3863V86.7557"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3132_962">
        <rect
          width="260"
          height="145.497"
          fill="white"
          transform="translate(60 57)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
