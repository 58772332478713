<template>
  <svg
    width="380"
    height="260"
    viewBox="0 0 380 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="380" height="260" fill="#FFE100" />
    <path
      d="M272.032 80.4067H97.3946C87.7497 80.4067 79.9309 88.2254 79.9309 97.8704V167.725C79.9309 177.37 87.7497 185.189 97.3946 185.189H272.032C281.677 185.189 289.495 177.37 289.495 167.725V97.8704C289.495 88.2254 281.677 80.4067 272.032 80.4067Z"
      fill="white"
    />
    <g style="mix-blend-mode:multiply">
      <path
        d="M219.641 97.8704H289.495V167.725C289.495 177.365 281.672 185.189 272.032 185.189H219.641V97.8704Z"
        fill="#F1F1F1"
      />
    </g>
    <path
      d="M79.9309 97.8704H289.495"
      stroke="#F1F1F1"
      stroke-linejoin="round"
    />
    <path
      d="M272.032 80.4067H97.3946C87.7497 80.4067 79.9309 88.2254 79.9309 97.8704V167.725C79.9309 177.37 87.7497 185.189 97.3946 185.189H272.032C281.677 185.189 289.495 177.37 289.495 167.725V97.8704C289.495 88.2254 281.677 80.4067 272.032 80.4067Z"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M114.858 71.6748C119.681 71.6748 123.59 67.7654 123.59 62.943C123.59 58.1205 119.681 54.2111 114.858 54.2111C110.036 54.2111 106.126 58.1205 106.126 62.943C106.126 67.7654 110.036 71.6748 114.858 71.6748Z"
      fill="#FFE100"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M114.858 66.5475C114.802 66.5475 114.753 66.5265 114.715 66.4881L111.442 63.2189C111.009 62.7858 110.772 62.213 110.772 61.6018C110.772 60.9905 111.009 60.4177 111.442 59.9846C111.875 59.5515 112.448 59.314 113.059 59.314C113.671 59.314 114.244 59.5515 114.677 59.9846L114.862 60.1697L115.047 59.9846C115.48 59.5515 116.056 59.3105 116.671 59.3105C117.321 59.3105 117.918 59.576 118.354 60.0545C119.179 60.9591 119.123 62.3667 118.229 63.2608L115.005 66.4846C114.967 66.523 114.914 66.544 114.862 66.544L114.858 66.5475Z"
      fill="white"
    />
    <path
      d="M116.667 59.576C117.24 59.576 117.771 59.81 118.159 60.2326C118.878 61.0185 118.823 62.2968 118.04 63.0792L114.858 66.2611L111.624 63.0338C111.243 62.6531 111.03 62.1431 111.03 61.6017C111.03 61.0604 111.24 60.5539 111.624 60.1697C112.005 59.789 112.515 59.576 113.056 59.576C113.597 59.576 114.104 59.7855 114.488 60.1697L114.858 60.54L115.228 60.1697C115.613 59.7855 116.123 59.5725 116.664 59.5725M116.664 59.0486C116.011 59.0486 115.354 59.3 114.855 59.7995C114.359 59.3035 113.706 59.052 113.052 59.052C112.399 59.052 111.75 59.3 111.25 59.7995C110.255 60.7949 110.255 62.4086 111.25 63.404L114.523 66.6732C114.614 66.764 114.732 66.8094 114.851 66.8094C114.97 66.8094 115.089 66.764 115.18 66.6732L118.403 63.4494C119.374 62.4784 119.465 60.8962 118.536 59.8798C118.03 59.328 117.345 59.052 116.657 59.052L116.664 59.0486Z"
      fill="#FFE100"
    />
    <path
      d="M149.786 128.432C153.569 128.432 156.639 125.365 156.639 121.579C156.639 117.793 153.569 114.726 149.786 114.726C146.003 114.726 142.933 117.793 142.933 121.579C142.933 125.365 146.003 128.432 149.786 128.432Z"
      fill="#4570F5"
    />
    <path
      d="M149.786 131.858C141.379 131.858 134.47 138.089 133.705 146.053C133.628 146.86 134.292 147.52 135.106 147.52H164.469C165.283 147.52 165.947 146.86 165.87 146.053C165.105 138.093 158.196 131.858 149.789 131.858H149.786Z"
      fill="#4570F5"
    />
    <path
      d="M254.568 176.457C264.213 176.457 272.032 168.638 272.032 158.993C272.032 149.348 264.213 141.53 254.568 141.53C244.923 141.53 237.104 149.348 237.104 158.993C237.104 168.638 244.923 176.457 254.568 176.457Z"
      fill="#4570F5"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M298.227 183.006C307.872 183.006 315.691 175.187 315.691 165.542C315.691 155.897 307.872 148.079 298.227 148.079C288.582 148.079 280.764 155.897 280.764 165.542C280.764 175.187 288.582 183.006 298.227 183.006Z"
      fill="#4570F5"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M254.568 152.256C256.87 149.954 260.645 150.003 262.863 152.434C264.949 154.718 264.749 158.281 262.563 160.471L255.309 167.725C254.9 168.134 254.236 168.134 253.828 167.725L246.458 160.366C244.219 158.127 244.219 154.495 246.458 152.256C248.697 150.017 252.329 150.017 254.568 152.256Z"
      fill="white"
    />
    <path
      d="M269.894 137.164C273.511 137.164 276.443 134.232 276.443 130.615C276.443 126.998 273.511 124.066 269.894 124.066C266.277 124.066 263.345 126.998 263.345 130.615C263.345 134.232 266.277 137.164 269.894 137.164Z"
      fill="#4570F5"
    />
    <path
      d="M269.894 128.257C270.663 127.489 271.92 127.506 272.657 128.317C273.352 129.078 273.286 130.266 272.556 130.992L270.139 133.409C270.003 133.545 269.783 133.545 269.646 133.409L267.191 130.957C266.444 130.21 266.444 129.001 267.191 128.254C267.938 127.506 269.147 127.506 269.894 128.254V128.257Z"
      fill="white"
    />
    <mask
      id="mask0_3132_871"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="79"
      y="80"
      width="211"
      height="106"
    >
      <path
        d="M272.032 80.494H97.3946C87.7497 80.494 79.9309 88.3128 79.9309 97.9578V167.813C79.9309 177.458 87.7497 185.276 97.3946 185.276H272.032C281.677 185.276 289.495 177.458 289.495 167.813V97.9578C289.495 88.3128 281.677 80.494 272.032 80.494Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3132_871)">
      <path
        d="M72.9454 171.403C88.6185 171.403 101.324 158.698 101.324 143.025C101.324 127.352 88.6185 114.646 72.9454 114.646C57.2724 114.646 44.5669 127.352 44.5669 143.025C44.5669 158.698 57.2724 171.403 72.9454 171.403Z"
        fill="black"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M293.907 67.26H241.516C236.693 67.26 232.784 71.1694 232.784 75.9919V93.4556C232.784 98.278 236.693 102.187 241.516 102.187H293.907C298.729 102.187 302.639 98.278 302.639 93.4556V75.9919C302.639 71.1694 298.729 67.26 293.907 67.26Z"
        fill="black"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <path
      d="M79.931 165.542C95.604 165.542 108.31 152.837 108.31 137.164C108.31 121.491 95.604 108.785 79.931 108.785C64.258 108.785 51.5525 121.491 51.5525 137.164C51.5525 152.837 64.258 165.542 79.931 165.542Z"
      fill="white"
      stroke="#4570F5"
      stroke-miterlimit="10"
    />
    <path
      d="M90.7725 135.955L74.416 126.511C73.4834 125.973 72.3203 126.644 72.3203 127.719V146.608C72.3203 147.684 73.4834 148.354 74.416 147.817L90.7725 138.372C91.705 137.834 91.705 136.49 90.7725 135.952V135.955Z"
      fill="#4570F5"
    />
    <path
      d="M298.227 62.943H245.836C241.014 62.943 237.104 66.8523 237.104 71.6748V89.1385C237.104 93.961 241.014 97.8704 245.836 97.8704H298.227C303.05 97.8704 306.959 93.961 306.959 89.1385V71.6748C306.959 66.8523 303.05 62.943 298.227 62.943Z"
      fill="#FFD700"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M219.641 124.066C224.463 124.066 228.373 120.157 228.373 115.334C228.373 110.512 224.463 106.602 219.641 106.602C214.818 106.602 210.909 110.512 210.909 115.334C210.909 120.157 214.818 124.066 219.641 124.066Z"
      fill="white"
      stroke="#4570F5"
      stroke-miterlimit="10"
    />
    <path
      d="M219.703 114.168C220.723 114.168 221.551 113.34 221.551 112.32C221.551 111.3 220.723 110.472 219.703 110.472C218.683 110.472 217.856 111.3 217.856 112.32C217.856 113.34 218.683 114.168 219.703 114.168Z"
      fill="#4570F5"
    />
    <path
      d="M219.703 115.09C217.59 115.09 215.827 116.553 215.435 118.488C215.348 118.914 215.69 119.309 216.123 119.309H223.284C223.717 119.309 224.059 118.914 223.972 118.488C223.577 116.55 221.817 115.09 219.703 115.09Z"
      fill="#4570F5"
    />
    <g style="mix-blend-mode:multiply">
      <path d="M263.3 110.968H237.104V115.334H263.3V110.968Z" fill="#EBEBEB" />
    </g>
    <g style="mix-blend-mode:multiply">
      <path
        d="M232.784 134.076C235.22 134.076 237.195 132.101 237.195 129.665C237.195 127.229 235.22 125.253 232.784 125.253C230.348 125.253 228.373 127.229 228.373 129.665C228.373 132.101 230.348 134.076 232.784 134.076Z"
        stroke="#EBEBEB"
        stroke-miterlimit="10"
      />
    </g>
    <g style="mix-blend-mode:multiply">
      <path
        d="M272.032 119.7H237.104"
        stroke="#EBEBEB"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </g>
    <g style="mix-blend-mode:multiply">
      <path
        d="M252.678 131.851H242.061"
        stroke="#EBEBEB"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </g>
    <g style="mix-blend-mode:multiply">
      <path
        d="M263.3 127.475H242.061"
        stroke="#EBEBEB"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </g>
    <g style="mix-blend-mode:multiply">
      <path
        d="M199.994 163.359H108.309C107.104 163.359 106.126 164.337 106.126 165.542C106.126 166.748 107.104 167.725 108.309 167.725H199.994C201.2 167.725 202.177 166.748 202.177 165.542C202.177 164.337 201.2 163.359 199.994 163.359Z"
        fill="#EBEBEB"
      />
    </g>
    <path
      d="M179.998 170.257C182.409 170.257 184.364 168.303 184.364 165.892C184.364 163.48 182.409 161.526 179.998 161.526C177.587 161.526 175.632 163.48 175.632 165.892C175.632 168.303 177.587 170.257 179.998 170.257Z"
      fill="black"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M181.235 169.021C183.646 169.021 185.601 167.066 185.601 164.655C185.601 162.244 183.646 160.289 181.235 160.289C178.823 160.289 176.869 162.244 176.869 164.655C176.869 167.066 178.823 169.021 181.235 169.021Z"
      fill="#FFD700"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M99.8221 91.2866C101.008 91.2866 101.97 90.3249 101.97 89.1385C101.97 87.9522 101.008 86.9905 99.8221 86.9905C98.6358 86.9905 97.6741 87.9522 97.6741 89.1385C97.6741 90.3249 98.6358 91.2866 99.8221 91.2866Z"
      stroke="#F1F1F1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M106.266 91.2866C107.453 91.2866 108.414 90.3249 108.414 89.1385C108.414 87.9522 107.453 86.9905 106.266 86.9905C105.08 86.9905 104.118 87.9522 104.118 89.1385C104.118 90.3249 105.08 91.2866 106.266 91.2866Z"
      stroke="#F1F1F1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M112.71 91.2866C113.897 91.2866 114.858 90.3249 114.858 89.1385C114.858 87.9522 113.897 86.9905 112.71 86.9905C111.524 86.9905 110.562 87.9522 110.562 89.1385C110.562 90.3249 111.524 91.2866 112.71 91.2866Z"
      stroke="#F1F1F1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M294.941 83.6934C296.76 83.6934 298.227 82.2229 298.227 80.4067C298.227 78.5905 296.757 77.12 294.941 77.12C293.124 77.12 291.657 78.5905 291.657 80.4067C291.657 82.2229 293.128 83.6934 294.941 83.6934Z"
      fill="black"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M250.202 71.6748V89.1385H256.751"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M285.129 89.1385H278.581V71.6748H285.129"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M261.117 71.6748V89.1385"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M265.483 71.6748L269.849 89.1385L274.215 71.6748"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M284.78 80.4067H278.581"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M315.691 132.808C322.925 132.808 328.789 126.944 328.789 119.711C328.789 112.477 322.925 106.613 315.691 106.613C308.457 106.613 302.593 112.477 302.593 119.711C302.593 126.944 308.457 132.808 315.691 132.808Z"
      fill="#FFD700"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M315.691 128.617C311.014 128.617 307.169 124.995 306.812 120.409H324.57C324.213 124.995 320.368 128.617 315.691 128.617Z"
      fill="black"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M309.429 120.409C311.144 121.789 313.323 122.225 315.691 122.225C318.059 122.225 320.239 121.792 321.954 120.409H309.429Z"
      fill="white"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M309.142 115.334V117.517"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M322.24 115.334V117.517"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M141.054 93.5045C148.288 93.5045 154.152 87.6404 154.152 80.4067C154.152 73.173 148.288 67.3089 141.054 67.3089C133.82 67.3089 127.956 73.173 127.956 80.4067C127.956 87.6404 133.82 93.5045 141.054 93.5045Z"
      fill="#FFD700"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M133.342 79.509L136.119 81.1122L133.772 82.7189" fill="#FFD700" />
    <path
      d="M133.342 79.509L136.119 81.1122L133.772 82.7189"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M148.766 79.509L145.989 81.1122L148.336 82.7189" fill="#FFD700" />
    <path
      d="M148.766 79.509L145.989 81.1122L148.336 82.7189"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M133.559 85.2092C135.144 87.6751 137.911 89.3132 141.054 89.3132C144.197 89.3132 146.964 87.6751 148.549 85.2092H133.559Z"
      fill="black"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M300.871 165.193H297.169C297.176 165.242 297.18 165.291 297.183 165.34C297.249 166.646 296.146 167.715 294.826 167.586C293.778 167.484 292.939 166.59 292.898 165.539C292.891 165.396 292.898 165.256 292.922 165.116C293.037 164.376 292.643 163.656 291.93 163.433L291.836 163.405C291.046 163.157 290.219 163.604 289.995 164.4C289.772 165.196 289.667 165.979 289.667 166.814C289.667 171.658 293.502 175.587 298.231 175.587C302.96 175.587 306.795 171.658 306.795 166.814V165.193H300.871Z"
      fill="white"
    />
    <path
      d="M295.077 167.596C295.028 167.596 294.979 167.596 294.927 167.596C293.837 167.54 292.95 166.656 292.894 165.563C292.884 165.385 292.898 165.21 292.929 165.043C293.062 164.348 292.653 163.663 291.975 163.45L291.769 163.384C291.011 163.146 290.222 163.576 290.002 164.341C289.778 165.127 289.656 165.954 289.656 166.814C289.656 171.658 293.491 175.587 298.22 175.587C299.258 175.587 300.253 175.399 301.172 175.053V173.848C301.172 170.446 298.454 167.676 295.07 167.596H295.077Z"
      fill="white"
    />
    <path
      d="M300.431 164.966C300.431 161.997 298.269 159.591 295.601 159.591C294.406 159.591 293.313 160.073 292.471 160.872C291.902 161.41 291.902 162.315 292.454 162.87L292.737 163.153C293.132 163.548 293.722 163.67 294.235 163.464C294.515 163.352 294.826 163.296 295.147 163.314C296.188 163.366 297.043 164.177 297.166 165.2H300.428V164.973L300.431 164.966Z"
      fill="white"
    />
    <path
      d="M299.307 154.799H299.474C300.246 154.799 300.872 155.424 300.872 156.196V165.193H297.91V156.196C297.91 155.424 298.535 154.799 299.307 154.799Z"
      fill="white"
    />
    <path
      d="M302.269 155.944H302.436C303.208 155.944 303.833 156.569 303.833 157.341V165.193H300.872V157.341C300.872 156.569 301.497 155.944 302.269 155.944Z"
      fill="white"
    />
    <path
      d="M305.227 157.397H305.395C306.166 157.397 306.792 158.022 306.792 158.794V165.193H303.83V158.794C303.83 158.022 304.455 157.397 305.227 157.397Z"
      fill="white"
    />
    <path
      d="M297.91 165.193H306.062"
      stroke="#B3CEFF"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M300.872 157.425V165.193"
      stroke="#B3CEFF"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M297.91 161.079V165.193"
      stroke="#B3CEFF"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M303.83 158.878V165.193"
      stroke="#B3CEFF"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>
