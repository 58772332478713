<template>
  <svg
    width="82"
    height="82"
    viewBox="0 0 82 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41 81C63.0914 81 81 63.0914 81 41C81 18.9086 63.0914 1 41 1C18.9086 1 1 18.9086 1 41C1 63.0914 18.9086 81 41 81Z"
      fill="white"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M40.6667 52.4999V60.6438"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M44.7388 60.9999L36.5949 60.9999"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M54.7335 38.458C54.7335 46.2233 48.4392 52.5247 40.6668 52.5247C32.8944 52.5247 26.6001 46.2304 26.6001 38.458"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M46.9596 28.3217C46.9596 24.7196 44.0592 21.7996 40.4815 21.7996C36.9037 21.7996 34.0034 24.7196 34.0034 28.3217V37.1178C34.0034 40.7199 36.9037 43.64 40.4815 43.64C44.0592 43.64 46.9596 40.7199 46.9596 37.1178V28.3217Z"
      fill="#4570F5"
      stroke="black"
      stroke-miterlimit="10"
    />
  </svg>
</template>
