<template>
  <svg
    width="380"
    height="260"
    viewBox="0 0 380 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="380" height="260" fill="#FFE100" />
    <path
      d="M303.695 115.509H242.153C234.156 115.509 227.673 121.992 227.673 129.989V168.905C227.673 176.902 234.156 183.385 242.153 183.385H303.695C311.692 183.385 318.175 176.902 318.175 168.905V129.989C318.175 121.992 311.692 115.509 303.695 115.509Z"
      fill="#F1F1F1"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M272.924 167.547C282.921 167.547 291.024 159.444 291.024 149.447C291.024 139.45 282.921 131.346 272.924 131.346C262.927 131.346 254.823 139.45 254.823 149.447C254.823 159.444 262.927 167.547 272.924 167.547Z"
      fill="white"
      stroke="#4570F5"
      stroke-miterlimit="10"
    />
    <path
      d="M272.924 147.51C274.741 147.51 276.215 146.037 276.215 144.22C276.215 142.402 274.741 140.929 272.924 140.929C271.107 140.929 269.633 142.402 269.633 144.22C269.633 146.037 271.107 147.51 272.924 147.51Z"
      fill="#4570F5"
    />
    <path
      d="M272.924 149.157C268.888 149.157 265.572 152.151 265.202 155.97C265.166 156.358 265.485 156.673 265.876 156.673H279.972C280.363 156.673 280.682 156.358 280.646 155.97C280.28 152.148 276.96 149.157 272.924 149.157Z"
      fill="#4570F5"
    />
    <path
      d="M227.673 115.509H293.287V130.441C293.287 138.435 286.8 144.922 278.807 144.922H227.673V115.509Z"
      fill="black"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M272.019 70.2575H210.477C202.48 70.2575 195.997 76.7406 195.997 84.7378V123.654C195.997 131.651 202.48 138.134 210.477 138.134H272.019C280.016 138.134 286.499 131.651 286.499 123.654V84.7378C286.499 76.7406 280.016 70.2575 272.019 70.2575Z"
      fill="#4570F5"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M168.846 70.2575H82.8692C72.8726 70.2575 64.7688 78.3613 64.7688 88.3579V165.285C64.7688 175.281 72.8726 183.385 82.8692 183.385H168.846C178.843 183.385 186.947 175.281 186.947 165.285V88.3579C186.947 78.3613 178.843 70.2575 168.846 70.2575Z"
      fill="white"
    />
    <path
      d="M64.7688 90.6205H186.947"
      stroke="#F1F1F1"
      stroke-miterlimit="10"
    />
    <path
      d="M83.7742 82.6671C85.0038 82.6671 86.0006 81.6704 86.0006 80.4408C86.0006 79.2112 85.0038 78.2144 83.7742 78.2144C82.5446 78.2144 81.5479 79.2112 81.5479 80.4408C81.5479 81.6704 82.5446 82.6671 83.7742 82.6671Z"
      fill="white"
      stroke="#F1F1F1"
      stroke-linecap="round"
    />
    <path
      d="M90.4532 82.6671C91.6827 82.6671 92.6795 81.6704 92.6795 80.4408C92.6795 79.2112 91.6827 78.2144 90.4532 78.2144C89.2236 78.2144 88.2268 79.2112 88.2268 80.4408C88.2268 81.6704 89.2236 82.6671 90.4532 82.6671Z"
      fill="white"
      stroke="#F1F1F1"
      stroke-linecap="round"
    />
    <path
      d="M97.1324 82.6671C98.3619 82.6671 99.3587 81.6704 99.3587 80.4408C99.3587 79.2112 98.3619 78.2144 97.1324 78.2144C95.9028 78.2144 94.906 79.2112 94.906 80.4408C94.906 81.6704 95.9028 82.6671 97.1324 82.6671Z"
      fill="white"
      stroke="#F1F1F1"
      stroke-linecap="round"
    />
    <path
      d="M168.846 70.2575H82.8692C72.8726 70.2575 64.7688 78.3613 64.7688 88.3579V165.285C64.7688 175.281 72.8726 183.385 82.8692 183.385H168.846C178.843 183.385 186.947 175.281 186.947 165.285V88.3579C186.947 78.3613 178.843 70.2575 168.846 70.2575Z"
      stroke="black"
      stroke-miterlimit="10"
    />
    <mask
      id="mask0_3132_4985"
      style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="195"
      y="69"
      width="92"
      height="70"
    >
      <path
        d="M272.019 70.2575H210.477C202.48 70.2575 195.997 76.7406 195.997 84.7379V123.654C195.997 131.651 202.48 138.134 210.477 138.134H272.019C280.016 138.134 286.499 131.651 286.499 123.654V84.7379C286.499 76.7406 280.016 70.2575 272.019 70.2575Z"
        fill="#4570F5"
        stroke="black"
        stroke-miterlimit="10"
      />
    </mask>
    <g mask="url(#mask0_3132_4985)">
      <path
        d="M285.721 129.112H227.582V138.162H285.721V129.112Z"
        fill="#FFD700"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M289.193 89.4493C296.138 86.5725 299.436 78.6101 296.559 71.6649C293.683 64.7197 285.72 61.4216 278.775 64.2984C271.83 67.1752 268.532 75.1375 271.409 82.0827C274.285 89.028 282.248 92.3261 289.193 89.4493Z"
        fill="black"
        stroke="black"
        stroke-miterlimit="10"
      />
    </g>
    <path
      d="M210.477 70.2575H268.399C278.39 70.2575 286.499 78.3665 286.499 88.3579V123.654C286.499 131.647 280.012 138.134 272.019 138.134H210.477C202.484 138.134 195.997 131.647 195.997 123.654V84.7378C195.997 76.7447 202.484 70.2575 210.477 70.2575V70.2575Z"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M144.563 109.47L142.702 109.615C140.964 116.569 135.266 121.547 127.769 121.547C103.949 121.547 103.29 83.7749 125.333 83.7749C130.817 83.7749 135.78 85.1976 139.476 89.462C142.228 92.6332 143.27 96.5502 144.606 100.42C145.247 102.281 146.04 104.446 146.68 106.162C147.252 107.696 146.192 109.347 144.563 109.47Z"
      fill="#F8D3B3"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M135.237 99.1566V104.069"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M117.405 121.514C115.66 121.438 113.988 121.17 112.402 120.226C108.572 117.949 107.03 113.213 107.649 108.963C108.474 103.273 108.948 96.8 111.844 91.6812C114.806 86.4429 119.476 83.7676 125.336 83.7676C129.948 83.7676 134.184 84.7813 137.616 87.6339L134.448 89.3969C129.177 92.3292 125.543 97.5457 124.707 103.519C124.062 108.149 124.58 113.051 126.097 117.083L127.773 121.54C124.536 121.54 122.024 121.54 118.788 121.54C118.201 121.54 117.98 121.536 117.408 121.511L117.405 121.514Z"
      fill="black"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M199.078 128.262C197.018 130.297 196.044 133.28 195.306 136.006C194.422 139.274 193.572 142.54 192.63 145.798C190.444 153.367 188.33 160.962 186.632 168.655C184.811 176.902 177.592 183.385 168.843 183.385H164.589L173.151 152.897C174.896 147.901 176.391 144.23 177.538 139.054C179.417 130.594 180.738 123.44 188.71 117.988L193.601 115.534L199.657 127.683L199.074 128.259L199.078 128.262Z"
      fill="#F8D3B3"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M218.623 108.725H186.947V129.084H218.623V108.725Z"
      fill="white"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M215.321 132.469H199.02L196.301 129.084H212.751L215.321 132.469Z"
      fill="black"
    />
    <path
      d="M196.337 132.747H188.869L186.339 129.084H198.216L196.337 132.747Z"
      fill="black"
    />
    <path
      d="M188.304 138.558C189.752 136.52 190.838 135.549 190.668 132.9L190.545 131.017C190.491 130.181 190.672 129.348 191.063 128.61L192.634 125.652C193.119 124.74 193.872 123.795 193.897 122.716C193.923 121.703 193.057 120.682 191.989 120.75C190.784 120.827 189.644 122.485 188.833 123.277C186.748 125.312 185.05 127.683 183.175 129.946"
      fill="#F8D3B3"
    />
    <path
      d="M188.304 138.558C189.752 136.52 190.838 135.549 190.668 132.9L190.545 131.017C190.491 130.181 190.672 129.348 191.063 128.61L192.634 125.652C193.119 124.74 193.872 123.795 193.897 122.716C193.923 121.703 193.057 120.682 191.989 120.75C190.784 120.827 189.644 122.485 188.833 123.277C186.748 125.312 185.05 127.683 183.175 129.946"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M225.099 129.479C223.959 127.737 221.305 123.94 218.347 121.46C217.442 120.7 217.12 119.031 217.79 118.365C218.525 117.608 219.437 117.789 220.447 118.72C222.901 120.979 225.164 123.589 227.046 126.325C229.327 129.641 231.811 134.159 233.248 138.134H217.96L217.801 137.486C217.355 135.654 216.856 134.025 215.64 132.534C214.499 131.133 212.925 129.294 211.172 127.491C210.369 126.666 210.054 125.33 210.868 124.631C211.563 124.063 212.327 124.28 213.084 125.015C215.831 127.683 218.261 130.861 219.694 132.86"
      fill="#F8D3B3"
    />
    <path
      d="M225.099 129.479C223.959 127.737 221.305 123.94 218.347 121.46C217.442 120.7 217.12 119.031 217.79 118.365C218.525 117.608 219.437 117.789 220.447 118.72C222.901 120.979 225.164 123.589 227.046 126.325C229.327 129.641 231.811 134.159 233.248 138.134H217.96L217.801 137.486C217.355 135.654 216.856 134.025 215.64 132.534C214.499 131.133 212.925 129.294 211.172 127.491C210.369 126.666 210.054 125.33 210.868 124.631C211.563 124.063 212.327 124.28 213.084 125.015C215.831 127.683 218.261 130.861 219.694 132.86"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M222.478 131.238C220.816 128.957 217.888 125.192 215.502 123.085C214.669 122.351 214.315 120.957 215.053 120.2C215.694 119.538 216.657 119.628 217.555 120.475C220.95 123.686 223.828 127.668 225.055 129.46"
      fill="#F8D3B3"
    />
    <path
      d="M222.478 131.238C220.816 128.957 217.888 125.192 215.502 123.085C214.669 122.351 214.315 120.957 215.053 120.2C215.694 119.538 216.657 119.628 217.555 120.475C220.95 123.686 223.828 127.668 225.055 129.46"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M219.691 132.856C218.275 130.872 215.893 127.744 213.268 125.2C212.447 124.403 212.193 123.104 212.921 122.329C213.533 121.779 214.383 121.989 215.194 122.702C217.862 125.058 220.82 128.928 222.478 131.241"
      fill="#F8D3B3"
    />
    <path
      d="M219.691 132.856C218.275 130.872 215.893 127.744 213.268 125.2C212.447 124.403 212.193 123.104 212.921 122.329C213.533 121.779 214.383 121.989 215.194 122.702C217.862 125.058 220.82 128.928 222.478 131.241"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M106.852 129.084H144.863C156.849 129.084 166.584 138.818 166.584 150.804V183.385H85.1318V150.804C85.1318 138.818 94.8663 129.084 106.852 129.084Z"
      fill="#4570F5"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M143.683 147.184H186.947V165.285C186.947 175.276 178.838 183.385 168.846 183.385H143.683V147.184Z"
      fill="white"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M143.683 147.184H137.171V183.385H143.683V147.184Z"
      fill="white"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M122.314 173.788L105.495 178.86V165.285H85.3054V183.385H137.17L133.587 177.897C131.157 174.176 126.567 172.503 122.31 173.785L122.314 173.788Z"
      fill="#F8D3B3"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M105.495 178.86L97.9939 181.123"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M132.667 183.385L129.713 178.86"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M129.083 183.385L126.129 178.86"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M125.503 183.385L123.617 180.5"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M218.623 108.725L209.192 115.328C205.348 118.021 200.225 118.021 196.381 115.328L186.95 108.725"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M218.623 114.383C221.747 114.383 224.281 111.849 224.281 108.725C224.281 105.6 221.747 103.066 218.623 103.066C215.498 103.066 212.964 105.6 212.964 108.725C212.964 111.849 215.498 114.383 218.623 114.383Z"
      fill="#FFD700"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M218.623 105.897L218.623 108.839"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M218.623 110.893V111.552"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M234.261 109.47L236.122 109.615C237.86 116.569 243.558 121.547 251.055 121.547C274.875 121.547 273.576 83.7749 253.491 83.7749C248.007 83.7749 243.044 85.1976 239.348 89.4621C236.596 92.6333 235.554 96.5502 234.218 100.42C233.577 102.281 232.784 104.446 232.144 106.162C231.572 107.696 232.632 109.347 234.261 109.47Z"
      fill="#F8D3B3"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M259.797 104.29H260.59C262.889 104.29 264.753 102.426 264.753 100.127V99.3087C264.753 97.0099 262.889 95.1456 260.59 95.1456H259.797"
      fill="#F8D3B3"
    />
    <path
      d="M259.797 104.29H260.59C262.889 104.29 264.753 102.426 264.753 100.127V99.3087C264.753 97.0099 262.889 95.1456 260.59 95.1456H259.797"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M243.59 99.1567V104.069"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M267.653 95.1456C265.626 88.7778 260.807 83.7676 253.495 83.7676C249.216 83.7676 245.263 84.6401 241.972 87.0438L244.401 89.4729C248.032 93.1038 252.959 95.1456 258.096 95.1456H267.653Z"
      fill="black"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M287.221 87.3597C294.166 84.4829 297.464 76.5206 294.587 69.5754C291.71 62.6302 283.748 59.3321 276.803 62.2089C269.858 65.0857 266.56 73.048 269.436 79.9932C272.313 86.9384 280.276 90.2365 287.221 87.3597Z"
      fill="#FFD700"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M276.743 70.8294C277.471 70.8294 278.061 71.4195 278.061 72.1471V77.418C278.061 78.1456 277.471 78.7357 276.743 78.7357C276.015 78.7357 275.425 78.1456 275.425 77.418V72.1471C275.425 71.4195 276.015 70.8294 276.743 70.8294Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M282.01 67.318C282.738 67.318 283.328 67.908 283.328 68.6357V80.9295C283.328 81.6571 282.738 82.2472 282.01 82.2472C281.283 82.2472 280.693 81.6571 280.693 80.9295V68.6357C280.693 67.908 281.283 67.318 282.01 67.318Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M287.281 70.8294C288.009 70.8294 288.599 71.4195 288.599 72.1471V77.418C288.599 78.1456 288.009 78.7357 287.281 78.7357C286.553 78.7357 285.963 78.1456 285.963 77.418V72.1471C285.963 71.4195 286.553 70.8294 287.281 70.8294Z"
      fill="black"
    />
    <path
      d="M186.947 147.184H192.232L186.947 167.156V147.184Z"
      fill="#F1F1F1"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M212.964 87.1597C214.838 87.1597 216.356 85.641 216.356 83.7677C216.356 81.8943 214.838 80.3756 212.964 80.3756C211.091 80.3756 209.572 81.8943 209.572 83.7677C209.572 85.641 211.091 87.1597 212.964 87.1597Z"
      fill="#4570F5"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M168.846 169.81C171.346 169.81 173.372 167.784 173.372 165.285C173.372 162.786 171.346 160.76 168.846 160.76C166.347 160.76 164.321 162.786 164.321 165.285C164.321 167.784 166.347 169.81 168.846 169.81Z"
      fill="white"
      stroke="#4570F5"
      stroke-miterlimit="10"
    />
    <path
      d="M115.881 129.084C115.881 131.582 120.88 133.609 126.524 133.609C132.168 133.609 137.167 131.582 137.167 129.084"
      fill="black"
    />
    <path
      d="M115.881 129.084C115.881 131.582 120.88 133.609 126.524 133.609C132.168 133.609 137.167 131.582 137.167 129.084"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M64.7687 142.659C72.2661 142.659 78.344 136.581 78.344 129.084C78.344 121.586 72.2661 115.509 64.7687 115.509C57.2712 115.509 51.1934 121.586 51.1934 129.084C51.1934 136.581 57.2712 142.659 64.7687 142.659Z"
      fill="#FFD700"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M64.7688 122.296V135.872"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M57.981 129.084H71.5563"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M227.673 201.486C237.669 201.486 245.773 193.382 245.773 183.385C245.773 173.389 237.669 165.285 227.673 165.285C217.676 165.285 209.572 173.389 209.572 183.385C209.572 193.382 217.676 201.486 227.673 201.486Z"
      fill="#FFD700"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path d="M227.673 189.681V193.764" stroke="black" stroke-miterlimit="10" />
    <path
      d="M234.869 182.484C234.869 186.459 231.648 189.684 227.669 189.684C223.691 189.684 220.469 186.462 220.469 182.484"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M230.981 177.285C230.981 175.456 229.499 173.973 227.671 173.973C225.843 173.973 224.36 175.456 224.36 177.285V181.753C224.36 183.582 225.843 185.065 227.671 185.065C229.499 185.065 230.981 183.582 230.981 181.753V177.285Z"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M321.639 173.648C326.256 171.735 328.449 166.441 326.537 161.823C324.624 157.205 319.33 155.012 314.712 156.925C310.094 158.838 307.901 164.132 309.814 168.75C311.727 173.368 317.021 175.561 321.639 173.648Z"
      fill="#F1F1F1"
      stroke="black"
      stroke-miterlimit="10"
    />
    <path
      d="M318.175 161.324V169.245"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M321.567 163.587V166.983"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M314.779 163.587V166.983"
      stroke="black"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>
