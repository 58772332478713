<template>
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="150" height="150" rx="75" fill="#FFE100" />
    <circle cx="75" cy="75" r="39.5" fill="white" stroke="black" />
    <path
      d="M98.5 75C98.5 85.9707 95.8309 95.8785 91.5418 103.027C87.2467 110.185 81.3828 114.5 75 114.5C68.6172 114.5 62.7533 110.185 58.4582 103.027C54.1691 95.8785 51.5 85.9707 51.5 75C51.5 64.0293 54.1691 54.1215 58.4582 46.973C62.7533 39.8145 68.6172 35.5 75 35.5C81.3828 35.5 87.2467 39.8145 91.5418 46.973C95.8309 54.1215 98.5 64.0293 98.5 75Z"
      stroke="black"
    />
    <path d="M37 75H115" stroke="black" />
    <path d="M41 55H109" stroke="black" />
    <path d="M41 95H109" stroke="black" />
    <path d="M75 115L75 35" stroke="black" />
    <path
      d="M91.9416 75.1311V82.475C91.9416 83.122 91.2369 83.5298 90.6785 83.2062L77.9585 75.8668C77.3957 75.5433 77.3957 74.7322 77.9585 74.4042L90.6785 67.0648C91.2413 66.7412 91.9416 67.1446 91.9416 67.7961V75.1399V75.1311Z"
      fill="#4570F5"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59.5 66.8777C59.5 66.1168 60.1168 65.5 60.8777 65.5H81.5126C82.2734 65.5 82.8902 66.1168 82.8902 66.8777V83.1223C82.8902 83.8832 82.2734 84.5 81.5126 84.5H60.8777C60.1168 84.5 59.5 83.8832 59.5 83.1223V66.8777Z"
      fill="#FFE100"
      stroke="black"
    />
    <path
      d="M71.1698 70.1958V79.5067"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M75.825 72.989V77.1789"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M66.5143 72.989V77.1789"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
