<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z"
      fill="#FFE100"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 18.3259C9.08545 20.1485 11.8548 21.3582 15 21.3582C18.1452 21.3582 20.9146 20.1485 22.5 18.3259"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5005 11.9517V14.342"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M19.2588 11.9514V14.3418"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
