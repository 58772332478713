<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1317 23.0716V18.345L13.6754 8.88867H8.9488L1.85889 15.9786H8.9488L16.0418 23.0716V30.1615L23.1317 23.0716Z"
      fill="#FFE100"
      stroke="black"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M19.656 3.09677C23.4185 1.03914 29.8029 2.21725 29.8029 2.21725C29.8029 2.21725 30.9811 8.60171 28.9234 12.3642C26.8658 16.1266 14.2458 24.5937 14.2458 24.5937L10.8361 21.1841L7.42649 17.7744C7.42649 17.7744 15.8936 5.1544 19.656 3.09677Z"
      fill="#FFE100"
      stroke="black"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <ellipse
      cx="19.7065"
      cy="12.3136"
      rx="2.71962"
      ry="2.71962"
      transform="rotate(45 19.7065 12.3136)"
      fill="white"
      stroke="black"
      stroke-width="2"
    />
    <path
      d="M21.3892 2.45752C21.3892 2.45752 21.8699 4.86134 24.5141 7.50555C27.1583 10.1498 29.5622 10.6305 29.5622 10.6305"
      stroke="black"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M8.71973 15.2905L16.38 22.9509"
      stroke="black"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M6.95166 20.5938L2.70902 24.8364"
      stroke="white"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M9.78027 23.4221L2.0021 31.2003"
      stroke="white"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M12.6084 26.2505L8.36576 30.4931"
      stroke="white"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </svg>
</template>
