<template>
  <svg
    width="380"
    height="260"
    viewBox="0 0 380 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="380" height="260" fill="#FFE100" />
    <g clip-path="url(#clip0_3132_2409)">
      <path
        d="M111.457 161.78V72.0166C111.457 62.1032 119.496 54.064 129.41 54.064H237.126C247.039 54.064 255.078 62.1032 255.078 72.0166V161.78H111.457Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M111.457 161.78V72.0166C111.457 62.1032 119.496 54.064 129.41 54.064H160.827L133.898 161.78H111.457Z"
        fill="#F1F1F1"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M205.181 54.064H237.126C247.039 54.064 255.078 62.1032 255.078 72.0166V161.78H232.11L205.181 54.064Z"
        fill="#F1F1F1"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M111.457 139.35H124.042C131.97 139.35 138.404 145.784 138.404 153.712V161.78H111.457V139.35Z"
        fill="black"
        stroke="black"
        stroke-linejoin="round"
      />
      <path
        d="M232.684 134.851H183.268V143.827H232.684V134.851Z"
        fill="#FFD700"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M210.197 125.874H165.315V143.827H210.197V125.874Z"
        fill="#FFD700"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M255.078 139.36H169.803V161.78H255.078V139.36Z"
        fill="black"
        stroke="black"
        stroke-linejoin="round"
      />
      <path
        d="M183.268 148.315H147.363V161.78H183.268V148.315Z"
        fill="#FFD700"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M165.315 148.315H160.827V161.78H165.315V148.315Z"
        fill="black"
        stroke="black"
        stroke-linejoin="round"
      />
      <path
        d="M165.315 143.827H308.936V188.709C308.936 198.618 300.893 206.661 290.983 206.661H183.268C173.358 206.661 165.315 198.618 165.315 188.709V143.827Z"
        fill="#F1F1F1"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M297.766 143.827L294.854 146.739C294.463 147.13 293.831 147.13 293.439 146.739L290.52 143.827"
        fill="black"
      />
      <path
        d="M297.766 143.827L294.854 146.739C294.463 147.13 293.831 147.13 293.439 146.739L290.52 143.827"
        stroke="black"
        stroke-linejoin="round"
      />
      <path
        d="M181.024 166.268C184.742 166.268 187.756 163.254 187.756 159.536C187.756 155.817 184.742 152.803 181.024 152.803C177.306 152.803 174.292 155.817 174.292 159.536C174.292 163.254 177.306 166.268 181.024 166.268Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
      />
      <g style="mix-blend-mode:multiply">
        <path
          d="M222.113 155.047H195.185V159.536H222.113V155.047Z"
          fill="#EBEBEB"
        />
      </g>
      <path
        d="M181.024 186.464C184.742 186.464 187.756 183.45 187.756 179.732C187.756 176.014 184.742 173 181.024 173C177.306 173 174.292 176.014 174.292 179.732C174.292 183.45 177.306 186.464 181.024 186.464Z"
        fill="white"
        stroke="#4570F5"
        stroke-miterlimit="10"
      />
      <g style="mix-blend-mode:multiply">
        <path
          d="M211.396 183.071H195.185"
          stroke="#EBEBEB"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
      <g style="mix-blend-mode:multiply">
        <path
          d="M227.607 176.393H195.185"
          stroke="#EBEBEB"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
      <path
        d="M277.519 197.685C289.913 197.685 299.96 187.638 299.96 175.244C299.96 162.85 289.913 152.803 277.519 152.803C265.125 152.803 255.078 162.85 255.078 175.244C255.078 187.638 265.125 197.685 277.519 197.685Z"
        fill="#FFD700"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M277.515 166.871C280.391 163.995 285.106 164.056 287.878 167.094C290.481 169.948 290.233 174.397 287.501 177.129L278.438 186.192C277.928 186.701 277.099 186.701 276.589 186.192L267.383 177C264.586 174.203 264.586 169.664 267.383 166.867C270.18 164.07 274.718 164.07 277.515 166.867V166.871Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M291.077 131.971C293.274 129.774 296.875 129.82 298.997 132.143C300.987 134.323 300.796 137.727 298.71 139.813L291.784 146.739C291.393 147.13 290.761 147.13 290.369 146.739L283.332 139.716C281.192 137.576 281.192 134.111 283.332 131.971C285.472 129.831 288.937 129.831 291.077 131.971Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M279.774 117.753C280.962 116.564 282.905 116.593 284.05 117.846C285.124 119.024 285.023 120.858 283.896 121.986L280.158 125.724C279.946 125.935 279.605 125.935 279.393 125.724L275.594 121.932C274.438 120.776 274.438 118.905 275.594 117.753C276.751 116.597 278.621 116.597 279.774 117.753Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M295.482 95.9438C296.312 95.1144 297.669 95.1324 298.466 96.0085C299.216 96.8307 299.145 98.1125 298.358 98.8988L295.748 101.509C295.601 101.656 295.364 101.656 295.217 101.509L292.567 98.8629C291.763 98.0586 291.763 96.7517 292.567 95.9438C293.371 95.136 294.678 95.1396 295.486 95.9438H295.482Z"
        fill="white"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M119.536 143.827H85.426C77.4941 143.827 71.064 150.257 71.064 158.189V192.299C71.064 200.231 77.4941 206.661 85.426 206.661H119.536C127.468 206.661 133.898 200.231 133.898 192.299V158.189C133.898 150.257 127.468 143.827 119.536 143.827Z"
        fill="#4570F5"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M192.244 125.874L196.732 107.922L201.22 125.874H192.244Z"
        fill="black"
        stroke="black"
        stroke-linejoin="round"
      />
      <path
        d="M174.292 125.874L178.78 107.922H196.732L192.244 125.874H174.292Z"
        fill="#FFD700"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M176.535 116.898H194.488L196.732 107.922H178.779L176.535 116.898Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M184.349 116L183.562 119.142H186.287L187.07 116H184.349Z"
        fill="black"
        stroke="black"
        stroke-linejoin="round"
      />
      <path
        d="M183.239 107.922V103.33C183.239 100.841 185.257 98.827 187.742 98.827C190.226 98.827 192.244 100.845 192.244 103.33V107.922H190.449V103.33C190.449 101.836 189.235 100.622 187.742 100.622C186.248 100.622 185.034 101.836 185.034 103.33V107.922H183.239Z"
        fill="#FFD700"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M129.41 80.9929C134.367 80.9929 138.386 76.9741 138.386 72.0166C138.386 67.0591 134.367 63.0403 129.41 63.0403C124.452 63.0403 120.434 67.0591 120.434 72.0166C120.434 76.9741 124.452 80.9929 129.41 80.9929Z"
        fill="white"
        stroke="#4570F5"
        stroke-miterlimit="10"
      />
      <path
        d="M173.573 63.0403H142.874V80.9929H173.573V63.0403Z"
        fill="#FFD700"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M149.607 67.5284V76.5047H152.975"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M167.559 76.5047H164.191V67.5284H167.559"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M155.219 67.5284V76.5047"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M157.463 67.5284L159.707 76.5047L161.947 67.5284"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M167.38 72.0166H164.191"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M181.024 166.268C184.742 166.268 187.756 163.254 187.756 159.536C187.756 155.817 184.742 152.803 181.024 152.803C177.306 152.803 174.292 155.817 174.292 159.536C174.292 163.254 177.306 166.268 181.024 166.268Z"
        fill="white"
        stroke="#4570F5"
        stroke-miterlimit="10"
      />
      <path
        d="M181.024 158.814C181.699 158.814 182.248 158.265 182.248 157.59C182.248 156.914 181.699 156.365 181.024 156.365C180.349 156.365 179.799 156.914 179.799 157.59C179.799 158.265 180.349 158.814 181.024 158.814Z"
        fill="#4570F5"
      />
      <path
        d="M181.024 159.428C179.523 159.428 178.288 160.541 178.151 161.963C178.137 162.106 178.255 162.225 178.403 162.225H183.645C183.788 162.225 183.907 162.106 183.896 161.963C183.76 160.541 182.525 159.428 181.024 159.428Z"
        fill="#4570F5"
      />
      <path
        d="M181.024 179.011C181.699 179.011 182.248 178.461 182.248 177.786C182.248 177.111 181.699 176.562 181.024 176.562C180.349 176.562 179.799 177.111 179.799 177.786C179.799 178.461 180.349 179.011 181.024 179.011Z"
        fill="#4570F5"
      />
      <path
        d="M181.024 179.625C179.523 179.625 178.288 180.738 178.151 182.159C178.137 182.303 178.255 182.422 178.403 182.422H183.645C183.788 182.422 183.907 182.303 183.896 182.159C183.76 180.738 182.525 179.625 181.024 179.625Z"
        fill="#4570F5"
      />
      <path
        d="M210.243 128.119L210.2 121.386H216.932L217.36 123.099C218.286 126.808 221.105 129.817 224.806 130.779C225.144 130.865 225.485 130.937 225.833 130.987C226.544 131.091 227.076 131.691 227.076 132.409V134.851H225.295C220.731 134.851 216.089 131.58 214.731 128.119V134.851"
        fill="#FFD700"
      />
      <path
        d="M210.243 128.119L210.2 121.386H216.932L217.36 123.099C218.286 126.808 221.105 129.817 224.806 130.779C225.144 130.865 225.485 130.937 225.833 130.987C226.544 131.091 227.076 131.691 227.076 132.409V134.851H225.295C220.731 134.851 216.089 131.58 214.731 128.119V134.851"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M212.487 121.386H214.732V122.507C214.732 123.124 214.229 123.63 213.608 123.63C212.99 123.63 212.484 123.128 212.484 122.507V121.386H212.487Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M214.732 128.118H210.243V134.851H214.732V128.118Z"
        fill="black"
        stroke="black"
        stroke-linejoin="round"
      />
      <g style="mix-blend-mode:multiply">
        <path
          d="M231.09 164.024H195.185"
          stroke="#EBEBEB"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
      <path
        d="M185.034 106.898H183.189V107.922H185.034V106.898Z"
        fill="black"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M192.244 106.898H190.398V107.922H192.244V106.898Z"
        fill="black"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M111.059 182.953H92.9985C91.0201 182.953 89.2823 181.628 88.7617 179.718L85.7564 168.702C85.6379 168.261 85.7277 167.79 86.0041 167.428C86.2806 167.065 86.7115 166.853 87.1675 166.853H114.448L111.059 182.953Z"
        fill="white"
      />
      <path
        d="M107.759 189.139H91.11C90.119 189.139 89.3147 188.335 89.3147 187.344C89.3147 186.353 90.119 185.549 91.11 185.549H107.759C108.291 185.549 108.757 185.172 108.865 184.651L113.741 161.492C114.197 159.32 116.143 157.744 118.362 157.744H121.97C122.961 157.744 123.766 158.548 123.766 159.539C123.766 160.53 122.961 161.334 121.97 161.334H118.362C117.831 161.334 117.364 161.711 117.256 162.232L112.38 185.391C111.924 187.563 109.978 189.139 107.759 189.139Z"
        fill="white"
      />
      <path
        d="M108.671 193.635C107.217 193.635 106.036 192.457 106.036 190.999C106.036 189.545 107.217 188.364 108.671 188.364C110.125 188.364 111.307 189.545 111.307 190.999C111.307 192.453 110.129 193.635 108.671 193.635Z"
        fill="white"
        stroke="#4570F5"
        stroke-miterlimit="10"
      />
      <path
        d="M94.0361 193.635C92.5819 193.635 91.4006 192.457 91.4006 190.999C91.4006 189.542 92.5819 188.364 94.0361 188.364C95.4902 188.364 96.6715 189.545 96.6715 190.999C96.6715 192.453 95.4938 193.635 94.0361 193.635Z"
        fill="white"
        stroke="#4570F5"
        stroke-miterlimit="10"
      />
      <path
        d="M109.497 181.653L112.341 168.149"
        stroke="#4570F5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M125.863 70.0993C125.863 69.7079 126.179 69.3883 126.573 69.3883H132.25C132.641 69.3883 132.961 69.7043 132.961 70.0993V74.3576C132.961 74.749 132.645 75.0685 132.25 75.0685H126.573C126.182 75.0685 125.863 74.7526 125.863 74.3576V70.0993Z"
        fill="#4570F5"
        stroke="black"
        stroke-miterlimit="10"
      />
      <path
        d="M129.41 69.3883L127.636 67.9701"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M129.41 69.3883V75.065"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M129.41 69.3883L131.184 67.9701"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M125.863 72.2249H132.957"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3132_2409">
        <rect
          width="240"
          height="154.967"
          fill="white"
          transform="translate(70 53)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
