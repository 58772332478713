<template>
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="150" height="150" rx="75" fill="#FFE100" />
    <rect
      x="38.5"
      y="47.5"
      width="73"
      height="23"
      rx="2.5"
      fill="white"
      stroke="black"
    />
    <circle cx="100" cy="58.3999" r="4.5" fill="#FFE100" stroke="black" />
    <circle cx="86" cy="58.3999" r="4.5" fill="#4570F5" stroke="black" />
    <rect
      x="38.5"
      y="79.5"
      width="73"
      height="23"
      rx="2.5"
      fill="white"
      stroke="black"
    />
    <circle cx="100" cy="91" r="4.5" fill="#4570F5" stroke="black" />
    <circle cx="86" cy="91" r="4.5" fill="#FFE100" stroke="black" />
    <rect x="45" y="54" width="6" height="10" fill="#D9D9D9" />
    <rect x="45" y="86" width="6" height="10" fill="#D9D9D9" />
    <rect x="45" y="86" width="1" height="10" fill="black" />
    <rect x="45" y="54" width="1" height="10" fill="black" />
    <rect x="53" y="54" width="6" height="10" fill="#D9D9D9" />
    <rect x="53" y="86" width="6" height="10" fill="#D9D9D9" />
    <rect x="53" y="86" width="1" height="10" fill="black" />
    <rect x="53" y="54" width="1" height="10" fill="black" />
    <rect x="61" y="54" width="6" height="10" fill="#D9D9D9" />
    <rect x="61" y="86" width="6" height="10" fill="#D9D9D9" />
    <rect x="61" y="86" width="1" height="10" fill="black" />
    <rect x="61" y="54" width="1" height="10" fill="black" />
  </svg>
</template>
