<template>
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="150" height="150" rx="75" fill="#FFE100" />
    <path
      d="M68.5375 111.357V92.8387C68.5375 92.4836 68.8253 92.1958 69.1804 92.1958C69.5354 92.1958 69.8232 92.4836 69.8232 92.8387V111.357C69.8232 111.712 69.5354 112 69.1804 112C68.8253 112 68.5375 111.712 68.5375 111.357Z"
      fill="black"
    />
    <path
      d="M80.3198 111.357V92.8387C80.3198 92.4836 80.6076 92.1958 80.9626 92.1958C81.3177 92.1958 81.6055 92.4836 81.6055 92.8387V111.357C81.6055 111.712 81.3177 112 80.9626 112C80.6076 112 80.3198 111.712 80.3198 111.357Z"
      fill="black"
    />
    <path d="M50 111.999H100" stroke="black" stroke-linecap="round" />
    <path
      d="M38 42.5H112C113.381 42.5 114.5 43.6193 114.5 45V91C114.5 92.3807 113.381 93.5 112 93.5H38C36.6193 93.5 35.5 92.3807 35.5 91V45C35.5 43.6193 36.6193 42.5 38 42.5Z"
      fill="white"
      stroke="black"
    />
    <rect x="43" y="51" width="21" height="4" fill="#D9D9D9" />
    <rect x="43" y="57" width="10" height="4" fill="#D9D9D9" />
    <path
      d="M43 85.5V78.5C43 78.2239 43.2239 78 43.5 78C43.7761 78 44 78.2239 44 78.5V85.5C44 85.7761 43.7761 86 43.5 86C43.2239 86 43 85.7761 43 85.5Z"
      fill="black"
    />
    <path
      d="M48 85.5V74.5C48 74.2239 48.2239 74 48.5 74C48.7761 74 49 74.2239 49 74.5V85.5C49 85.7761 48.7761 86 48.5 86C48.2239 86 48 85.7761 48 85.5Z"
      fill="black"
    />
    <path
      d="M53 85.5V70.5C53 70.2239 53.2239 70 53.5 70C53.7761 70 54 70.2239 54 70.5V85.5C54 85.7761 53.7761 86 53.5 86C53.2239 86 53 85.7761 53 85.5Z"
      fill="black"
    />
    <path
      d="M58 85.5V78.5C58 78.2239 58.2239 78 58.5 78C58.7761 78 59 78.2239 59 78.5V85.5C59 85.7761 58.7761 86 58.5 86C58.2239 86 58 85.7761 58 85.5Z"
      fill="black"
    />
    <path
      d="M63 85.5V78.5C63 78.2239 63.2239 78 63.5 78C63.7761 78 64 78.2239 64 78.5V85.5C64 85.7761 63.7761 86 63.5 86C63.2239 86 63 85.7761 63 85.5Z"
      fill="black"
    />
    <circle
      cx="89"
      cy="68"
      r="17.5"
      transform="rotate(90 89 68)"
      fill="#4570F5"
      stroke="black"
    />
    <path
      d="M89.5 50.507C98.7677 50.767 106.233 58.2323 106.493 67.5L89.5 67.5L89.5 50.507Z"
      fill="#FFE100"
      stroke="black"
    />
  </svg>
</template>
